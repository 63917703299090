import gql from "graphql-tag";

export const GET_BLOCKS: any = (timestamps: number[]) => {
  let queryString = "query blocks {";
  queryString += timestamps.map((timestamp) => {
    return `t${timestamp}:blocks(first: 1, orderBy: timestamp, orderDirection: desc, where: { timestamp_gt: ${timestamp}, timestamp_lt: ${
      timestamp + 600
    } }) {
      number
    }`;
  });
  queryString += "}";
  return gql(queryString);
};

export const HOURLY_PAIR_RATES = (pairAddress: string, blocks: any[]) => {
  let queryString = "query blocks {";
  queryString += blocks.map(
    (block) => `
      t${block.timestamp}: pair(id:"${pairAddress}", block: { number: ${block.number} }) { 
        token0Price
        token1Price
      }
    `
  );

  queryString += "}";
  return gql(queryString);
};

export const HOURLY_PAIR_RATES_V3 = (pairAddress: string, blocks: any[]) => {
  let queryString = "query blocks {";
  queryString += blocks.map(
    (block) => `
      t${block.timestamp}: pool(id:"${pairAddress}", block: { number: ${block.number} }) { 
        token0Price
        token1Price
      }
    `
  );

  queryString += "}";
  return gql(queryString);
};
