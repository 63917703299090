import { ApolloClient } from "apollo-client";
import { InMemoryCache, NormalizedCacheObject } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { ChainId } from "@uniswap/sdk";

export type ApolloChainMap = Readonly<{
  [chainId in ChainId]: ApolloClient<NormalizedCacheObject> | undefined;
}>;

export const v2Client: ApolloChainMap = {
  [ChainId.MATIC]: new ApolloClient({
    link: new HttpLink({
      uri: "https://api.fura.org/subgraphs/name/quickswap",
    }),
    cache: new InMemoryCache(),
  }),
  [ChainId.MUMBAI]: undefined,
  [ChainId.DOGECHAIN]: undefined,
  [ChainId.DOEGCHAIN_TESTNET]: undefined,
  [ChainId.ZKTESTNET]: undefined,
};

export const v3Client: ApolloChainMap = {
  [ChainId.MATIC]: new ApolloClient({
    link: new HttpLink({
      uri: "https://api.thegraph.com/subgraphs/name/sameepsi/quickswap-v3",
    }),
    cache: new InMemoryCache(),
  }),
  [ChainId.MUMBAI]: undefined,
  [ChainId.DOGECHAIN]: new ApolloClient({
    link: new HttpLink({
      uri: "https://graph-node.dogechain.dog/subgraphs/name/quickswap/dogechain-info",
    }),
    cache: new InMemoryCache(),
  }),
  [ChainId.DOEGCHAIN_TESTNET]: undefined,
  [ChainId.ZKTESTNET]: undefined,
};

export const blockClient: ApolloChainMap = {
  [ChainId.MATIC]: new ApolloClient({
    link: new HttpLink({
      uri: "https://api.fura.org/subgraphs/name/quickswap",
    }),
    cache: new InMemoryCache(),
  }),
  [ChainId.MUMBAI]: undefined,
  [ChainId.DOGECHAIN]: new ApolloClient({
    link: new HttpLink({
      uri: "https://graph-node.dogechain.dog/subgraphs/name/quickswap/dogechain-blocklytics",
    }),
    cache: new InMemoryCache(),
  }),
  [ChainId.DOEGCHAIN_TESTNET]: undefined,
  [ChainId.ZKTESTNET]: undefined,
};
