import React from "react";
import { useLocation } from "react-router-dom";
import { TradingViewChart } from "components";
import { ChainId } from "@uniswap/sdk";

const TradingViewPage: React.FC = () => {
  const { search } = useLocation();
  const searchQueries = search
    .substring(1)
    .split("&")
    .map((str) => str.split("="));

  const pairAddress = searchQueries.find((query) => query[0] === "pairAddress");
  const pairName = searchQueries.find((query) => query[0] === "pairName");
  const tokenReversed = searchQueries.find(
    (query) => query[0] === "tokenReversed"
  );
  const chainId = searchQueries.find((query) => query[0] === "chainId");
  const isV3 = searchQueries.find((query) => query[0] === "isV3");

  return pairAddress &&
    pairAddress.length === 2 &&
    pairName &&
    pairName.length === 2 ? (
    <TradingViewChart
      pairAddress={pairAddress[1]}
      pairName={pairName[1]}
      pairTokenReversed={
        tokenReversed &&
        tokenReversed.length === 2 &&
        tokenReversed[1] === "true"
      }
      chainId={
        chainId && chainId.length === 2
          ? (Number(chainId[1]) as ChainId)
          : ChainId.MATIC
      }
      isV3={isV3 && isV3.length === 2 && isV3[1] === "true"}
    />
  ) : (
    <>
      You need to add pairAddress, pairName and pairTokenReversed parameters to
      the url.
    </>
  );
};

export default TradingViewPage;
