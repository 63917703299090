import { ApolloClient } from "apollo-client";
import { getAddress } from "@ethersproject/address";
import { v3Client, v2Client, blockClient } from "apollo/client";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import weekOfYear from "dayjs/plugin/weekOfYear";
import {
  GET_BLOCKS,
  HOURLY_PAIR_RATES,
  HOURLY_PAIR_RATES_V3,
} from "apollo/queries";
import { ChainId } from "@uniswap/sdk";

dayjs.extend(utc);
dayjs.extend(weekOfYear);

export function isAddress(value: string | null | undefined): string | false {
  try {
    return getAddress(value || "");
  } catch {
    return false;
  }
}

export async function splitQuery(
  query: any,
  localClient: ApolloClient<any>,
  vars: any[],
  list: any[],
  skipCount = 100
): Promise<any> {
  let fetchedData = {};
  let allFound = false;
  let skip = 0;

  while (!allFound) {
    let end = list.length;
    if (skip + skipCount < list.length) {
      end = skip + skipCount;
    }
    const sliced = list.slice(skip, end);
    const result = await localClient.query({
      query: query(...vars, sliced),
      fetchPolicy: "network-only",
    });
    fetchedData = {
      ...fetchedData,
      ...result.data,
    };
    if (
      Object.keys(result.data).length < skipCount ||
      skip + skipCount > list.length
    ) {
      allFound = true;
    } else {
      skip += skipCount;
    }
  }

  return fetchedData;
}

export async function getBlocksFromTimestamps(
  chainId: ChainId,
  timestamps: number[],
  skipCount = 500
): Promise<
  {
    timestamp: string;
    number: any;
  }[]
> {
  const client = blockClient[chainId];
  if (timestamps?.length === 0 || !client) {
    return [];
  }

  const fetchedData: any = await splitQuery(
    GET_BLOCKS,
    client,
    [],
    timestamps,
    skipCount
  );

  const blocks = [];
  if (fetchedData) {
    for (const t in fetchedData) {
      if (fetchedData[t].length > 0) {
        blocks.push({
          timestamp: t.split("t")[1],
          number: fetchedData[t][0]["number"],
        });
      }
    }
  }
  return blocks;
}

export const getRateData = async (
  pairAddress: string,
  latestBlock: number,
  interval: number,
  startTime: number,
  pairTokenReversed: boolean,
  chainId?: ChainId,
  isV3 = false
) => {
  try {
    const utcEndTime = dayjs.utc();
    let time = startTime;

    // create an array of hour start times until we reach current hour
    const timestamps = [];
    while (time <= utcEndTime.unix()) {
      timestamps.push(time);
      time += interval;
    }

    if (!chainId) return [];
    const client = isV3 ? v3Client[chainId] : v2Client[chainId];
    // backout if invalid timestamp format
    if (timestamps.length === 0 || !client) {
      return [];
    }

    // once you have all the timestamps, get the blocks for each timestamp in a bulk query
    let blocks;

    blocks = await getBlocksFromTimestamps(chainId, timestamps, 100);

    // catch failing case
    if (!blocks || blocks?.length === 0) {
      return [];
    }

    if (latestBlock) {
      blocks = blocks.filter((b) => {
        return parseFloat(b.number) <= latestBlock;
      });
    }

    const result = await splitQuery(
      isV3 ? HOURLY_PAIR_RATES_V3 : HOURLY_PAIR_RATES,
      client,
      [pairAddress],
      blocks,
      100
    );

    // format token ETH price results
    const values = [];
    for (const row in result) {
      const timestamp = row.split("t")[1];
      if (timestamp) {
        values.push({
          timestamp,
          rate: pairTokenReversed
            ? Number(result[row]?.token0Price)
            : Number(result[row]?.token1Price),
        });
      }
    }
    return values;
  } catch (e) {
    console.log(e);
    return [];
  }
};
